<template>
  <div
    class="scrollContainer"
    v-loading="loading"
    element-loading-text="请稍后.."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-form
      class="filter-form rpm-query-from"
      :rules="rules"
      :model="selectEditMsg"
      :label-position="'top'"
      ref="candidateFormRef"
    >
      <div class="inductionTitleLab inductionTitleMsg">基本信息</div>
      <div class="flex flexBetw AlCenter">
        <div class="flex-1">
          <div class="flex flexBetw AlCenter flex-1 rowItem">
            <div class="flex-1">
              <el-form-item
                label="English Name"
                class="w100 pr24"
                prop="englishName"
              >
                <el-input
                  placeholder="请输入English Name"
                  v-model="selectEditMsg.englishName"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item
                label="Local Name"
                class="w100 pr24"
                prop="localName"
              >
                <el-input
                  placeholder="请输入Local Name"
                  v-model="selectEditMsg.localName"
                >
                </el-input
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="P File Number" class="w100 pr24">
                <el-input
                  placeholder="请输入P File Number"
                  v-model="selectEditMsg.pfileNumber"
                >
                </el-input
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="ID Type" class="w100 pr24">
                <el-select
                  clearable
                  v-model="selectEditMsg.idType"
                  filterable
                  placeholder="请选择"
                  class="industry-selector"
                >
                  <el-option
                    v-for="item in idTypeList"
                    :key="item.label"
                    :label="item.value"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="flex flexBetw AlCenter flex-1 rowItem">
            <div class="flex-1">
              <el-form-item
                label="ID/Passport No"
                class="w100 pr24"
                prop="idPassportNo"
              >
                <el-input
                  placeholder="请输入ID/Passport No"
                  v-model="selectEditMsg.idPassportNo"
                  @change="idChange"
                >
                </el-input
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Gender" class="w100 pr24">
                <el-select
                  clearable
                  v-model="selectEditMsg.gender"
                  filterable
                  placeholder="请选择"
                  class="industry-selector"
                >
                  <el-option
                    v-for="item in genderList"
                    :key="item.label"
                    :label="item.value"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Birthday" class="w100 pr24">
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请输入Birthday"
                  v-model="selectEditMsg.birthday"
                  style="width: 100%"
                >
                </el-date-picker
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Replacement LLID" class="w100 pr24">
                <el-input
                  placeholder="请输入Replacement LLID"
                  v-model="selectEditMsg.replacementLlid"
                >
                </el-input
                >     
              </el-form-item>
            </div>
          </div>
          <div class="flex flexBetw AlCenter flex-1 rowItem">
            <div class="flex-1">
              <el-form-item label="Internal Referrer Info" class="w100 pr24">
                <el-input
                  placeholder="请输入Internal Referrer Info"
                  v-model="selectEditMsg.internalReferrerInfo"
                >
                </el-input
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Career Setting ID" class="w100 pr24">
                <el-input
                  placeholder="请输入Career Setting ID"
                  v-model="selectEditMsg.careerSettingId"
                >
                </el-input
                >     
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Received Paf Time" class="w100 pr24">
                <el-date-picker
                  v-model="selectEditMsg.receivedPafTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                    </el-date-picker
                > 
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Employee LIID" class="w100 pr24">
                <el-input
                  placeholder="请输入Employee LIID"
                  v-model="selectEditMsg.employeeLiid"
                >
                </el-input
                >     
              </el-form-item>
            </div>
          </div>
          <div class="flex flexBetw AlCenter flex-1 rowItem">
            <div class="flex-1">
              <el-form-item label="IMac Finished Time" class="w100 pr24">
                <el-date-picker
                  v-model="selectEditMsg.imacFinishedTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                   
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Replacement Name" class="w100 pr24">
                <el-input
                  placeholder="请输入Replacement Name"
                  v-model="selectEditMsg.replacementName"
                >
                </el-input
                >     
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Work Life Duration" class="w100 pr24">
                <el-input
                  placeholder="请输入Work Life Duration"
                  v-model="selectEditMsg.workLifeDuration"
                >
                </el-input
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Last Review Date" class="w100 pr24">
                <el-date-picker
                  v-model="selectEditMsg.lastReviewDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                    </el-date-picker
                >     
              </el-form-item>
            </div>
          </div>
          <div class="flex flexBetw AlCenter flex-1 rowItem">
            <div class="flex-1">
              <el-form-item label="Local Employee Code" class="w100 pr24">
                <el-input
                  placeholder="请输入Local Employee Code"
                  v-model="selectEditMsg.localEmployeeCode"
                >
                </el-input
                >       
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Join Date" class="w100 pr24" prop="joinDate">
                <el-date-picker
                  v-model="selectEditMsg.joinDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Challenge Group" class="w100 pr24">
                <el-select
                  clearable
                  v-model="selectEditMsg.challengeGroup"
                  filterable
                  placeholder="请选择"
                  class="industry-selector"
                >
                  <el-option
                    v-for="item in ChallengeGroupList"
                    :key="item.label"
                    :label="item.value"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex-1"></div>
          </div>
        </div>

        <div class="upContainer"></div>
      </div>

      <div>
        <div class="inductionTitleLab inductionTitleMsg">民族&地址</div>
        <div class="flex flexBetw AlCenter">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1">
                <el-form-item label="Nationality" class="w100 pr24">
                  <el-select
                    clearable
                    v-model="selectEditMsg.nationality"
                    filterable
                    placeholder="请选择"
                    class="industry-selector"
                  >
                    <el-option
                      v-for="item in countryList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Nation" class="w100 pr24">
                  <el-select
                    disabled
                    clearable
                    v-model="selectEditMsg.nation"
                    filterable
                    placeholder="请选择"
                    class="industry-selector"
                  >
                    <el-option
                      v-for="item in nationList"
                      :key="item.label"
                      :label="item.value"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Ethnicity" class="w100 pr24">
                  <el-select
                    clearable
                    v-model="selectEditMsg.ethnicity"
                    filterable
                    placeholder="请选择"
                    class="industry-selector"
                  >
                    <el-option
                      v-for="item in nationList"
                      :key="item.label"
                      :label="item.value"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Native Place" class="w100 pr24">
                  <el-input
                    placeholder="请输入Native Place"
                    v-model="selectEditMsg.nativePlace"
                  >
                  </el-input
                  >     
                </el-form-item>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-3">
                <el-form-item label="Permanent Address" class="w100 pr24">
                  <el-cascader
                    size="large"
                    clearable
                    filterable
                    :options="options"
                    v-model="selectEditMsg.permanentAddressList"
                    style="width: 100%"
                  >
                  </el-cascader>
                  <!--                      @change="handleChange2"-->
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item
                  label="Permanent Address Detail"
                  class="w100 pr24"
                >
                  <el-input
                    placeholder="请输入Permanent Address Detail"
                    v-model="selectEditMsg.permanentAddressDetail"
                  >
                  </el-input
                  >     
                </el-form-item>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-3">
                <el-form-item label="Present Address" class="w100 pr24">
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="selectEditMsg.presentAddressList"
                    style="width: 100%"
                  >
                  </el-cascader>
                </el-form-item>
              </div>

              <div class="flex-1">
                <el-form-item label="Present Address Detail" class="w100 pr24">
                  <el-input
                    placeholder="请输入Present Address Detail"
                    v-model="selectEditMsg.presentAddressDetail"
                  >
                  </el-input
                  >     
                </el-form-item>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1">
                <el-form-item label="Residence Address" class="w100 pr24">
                  <el-input
                    placeholder="Residence Address"
                    v-model="selectEditMsg.residenceAddress"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="户口性质" class="w100 pr24">
                  <!--                    <el-input-->
                  <!--                      placeholder="请输入户口性质"-->
                  <!--                      v-model="selectEditMsg.householdRegister"-->
                  <!--                    >-->
                  <!--                    </el-input-->
                  <el-select
                    v-model="selectEditMsg.householdRegister"
                    placeholder="户口性质"
                  >
                    <el-option
                      v-for="item in householdRegisterList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                         
                </el-form-item>
              </div>
              <div class="flex-1"></div>
              <div class="flex-1"></div>
            </div>
          </div>
          <div class="upContainer"></div>
        </div>
        <!-- </el-form> -->
      </div>
      <div>
        <div class="inductionTitleLab inductionTitleMsg">联系方式</div>
        <!-- <el-form class="filter-form rpm-query-from"> -->
        <div class="flex flexBetw AlCenter">
          <div class="flex-1">
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1">
                <el-form-item
                  label="Mobile Number"
                  class="w100 pr24"
                  prop="mobileNumber"
                >
                  <el-input
                    placeholder="请输入Mobile Number"
                    v-model="selectEditMsg.mobileNumber"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Personal Mail Address" class="w100 pr24" prop="personalMailAddress">
                  <el-input
                    placeholder="请输入Personal Mail Address"
                    v-model="selectEditMsg.personalMailAddress"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Business Email" class="w100 pr24"  prop="businessMail">
                  <el-input
                    placeholder="请输入Business Email"
                    v-model="selectEditMsg.businessMail"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Wechat ID" class="w100 pr24">
                  <el-input
                    placeholder="请输入Wechat ID"
                    v-model="selectEditMsg.wechatId"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1">
                <el-form-item label="Bank Name" class="w100 pr24">
                  <el-input
                    placeholder="请输入Bank Name"
                    v-model="selectEditMsg.bankName"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Bank Account" class="w100 pr24">
                  <el-input
                    placeholder="请输入Bank Account"
                    v-model="selectEditMsg.bankAccount"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Beneficiary Name" class="w100 pr24">
                  <el-input
                    placeholder="请输入Beneficiary Name"
                    v-model="selectEditMsg.beneficiaryName"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1"></div>
            </div>
            <div class="flex flexBetw AlCenter flex-1 rowItem">
              <div class="flex-1">
                <el-form-item
                  label="Special requirement for computer"
                  class="w100 pr24"
                >
                  <el-input
                    type="textarea"
                    placeholder="请输入Special requiremen tfor computer"
                    v-model="selectEditMsg.specialRequirementForComputer"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Remark" class="w100 pr24">
                  <el-input
                    type="textarea"
                    placeholder="请输入Remark"
                    v-model="selectEditMsg.remark"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1">
                <el-form-item label="Warning Letter" class="w100 pr24">
                  <el-input
                    type="textarea"
                    placeholder="请输入Warning Letter"
                    v-model="selectEditMsg.warningLetter"
                  >
                  </el-input
                  >       
                </el-form-item>
              </div>
              <div class="flex-1"></div>
            </div>
          </div>
          <div class="upContainer"></div>
        </div>
        <!-- </el-form> -->
      </div>
    </el-form>
  </div>
</template>

<script>
import { rules } from '@/views/candidateManage/rules'
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: 'userManage',
  components: {},
  data() {
    return {
      householdRegisterList: [
        //户籍列表

        {
          label: '本地非农业户口（本地城镇）',
          value: '本地非农业户口（本地城镇）'
        },
        {
          label: '外地非农业户口（外地城镇）',
          value: '外地非农业户口（外地城镇）'
        },
        {
          label: '本地农业户口（本地农村）',
          value: '本地农业户口（本地农村）'
        },
        {
          label: '外地农业户口（外地农村）',
          value: '外地农业户口（外地农村）'
        }
      ],
      options: regionData,
      selectedOptions: ['140000', '140400', '140405'],
      statusList: [
        { value: '是', label: '1' },
        { value: '否', label: '2' }
      ],
      genderList: [
        { value: '男', label: '1' },
        { value: '女', label: '2' }
      ],
      ChallengeGroupList: [
        { value: '是', label: '1' },
        { value: '否', label: '2' }
      ],
      idTypeList: [
        { value: 'ID Card', label: 'ID Card' },
        { value: 'Passport', label: 'Passport' },
        { value: 'Other', label: 'Other' }
      ],
      countryList: [],
      nationList: [],
      loading: true,
      selectEditMsg: {
        EnglishName: ''
      }, // 选中修改的一系列用户信息
      rules: rules
    }
  },

  computed: {},

  created() {
    let jumpFlag = this.$route.query.jumpFlag
    let candidateInformationId = this.$route.query.candidateInformationId
    this.jumpParams = {
      jumpFlag,
      candidateInformationId
    }
    this.getCandidateInfo(this.jumpParams.candidateInformationId)
    this.getCountry()
    this.getNationList()
  },
  methods: {
    idChange(val){
      console.log(val);
      if(val){
        let genderCode; // 性别代码
        if (val.length === 18) { // 二代身份证号码长度为18位（第17位为性别代码）
          genderCode = val.charAt(16);
        } else if (val.length === 15) { // 一代身份证号码长度为15位（第15位为性别代码）
          genderCode = val.charAt(14);
        }
        if (genderCode && !isNaN(genderCode)) {
          // 两代身份证号码的性别代码都为男奇女偶
          if (parseInt(genderCode) % 2 === 0) {
            this.selectEditMsg.gender = '2';
          }else {
            this.selectEditMsg.gender = '1';
          }
        }
      }
    },
    handleChange(value) {
      //处理编辑提交该地址的值
      if (value.length) {
        this.selectEditMsg.presentAddress = [] //清理上一次数据
        value.map((d) => {
          this.selectEditMsg.presentAddress.push(CodeToText[d])
        })
      } else {
        this.selectEditMsg.presentAddress = []
      }
    },
    handleChange2(value) {
      console.log(value)
      //处理编辑提交该地址的值
      if (value.length) {
        this.selectEditMsg.permanentAddress = [] //清理上一次数据
        value.map((d) => {
          this.selectEditMsg.permanentAddress.push(CodeToText[d])
        })
      } else {
        this.selectEditMsg.permanentAddress = []
      }
    },
    async getCandidateInfo(candidateId) {
      const { data, code, msg } = await this.$Api.candidate.getCandidateInfo({
        id: candidateId ? candidateId : 1
      })
      this.loading = false
      if (code === 200) {
        this.selectEditMsg = data
        //处理回显问题
        this.selectEditMsg.presentAddress = ['山西省', '长治市', '屯留区']
        this.selectEditMsg.presentAddress.map((d) => {
          this.selectedOptions.push(TextToCode[d])
        })
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    save() {
      this.$refs.candidateFormRef.validate((valid) => {
        console.log('校验成功失败', valid)
        if (valid) {
          this.submit()
        } else {
          return
        }
      })
    },
    async submit() {
      const { data, code, msg } = await this.$Api.candidate.editCandidate({
        ...this.selectEditMsg
      })
      this.buttonDisable = false
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功!'
        })
        this.$router.replace({
          path: '/employees/hired'
        })
      } else {
        this.$message({
          type: 'info',
          message: msg
        })
      }
    },
    async getNationList() {
      const { code, data } = await this.$Api.candidate.getNationList({})
      if (code === 200) {
        this.nationList = data
      }
    },
    async getCountry() {
      const { data, code, msg } = await this.$Api.induction.getCountry({})
      if (code === 200) {
        this.countryList = data
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
  .el-select {
    width: 100%;
  }
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.editContainer {
  width: 100%;
  padding: 0px 10px 0 10px;
  box-sizing: border-box;
}
.scrollContainer {
  //height: calc(100vh - 250px) !important ;
  //padding-bottom: 20px;
  box-sizing: border-box;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}
.inductionTitleLab {
  width: 100%;
  //border-bottom: 1px solid #828282;
  margin-bottom: 6px;
}
.inductionTitleMsg {
  font-size: 16px;
  font-weight: bold;
  //padding: 20px 0 4px 20px;
  box-sizing: border-box;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}

.el-range-editor {
  width: 100%;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonunActive {
  width: 132px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent {
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
<style lang="scss">
.editInduction-list-page {
  .el-icon-minus:before {
    content: '';
    transform: rotate(180deg);
  }
  .el-icon-plus:before {
    content: '';
  }

  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }

  .irisePocCardTable {
    //height: 100%;
    .el-card__header {
      height: 65px;
    }
  }
}
</style>
