<template>
  <div class="employeesItemEdit">
    <PocCardTable>
      <template v-slot:headerTitle> <span>员工列表</span> / 编辑 </template>
      <template slot="headerGroup">
        <el-button @click="back">取消</el-button>
        <!--        保存在上传页面不需要 ，上传自动保存-->
        <el-button
          type="primary"
          @click="save"
          v-if="buttonActive !== 'upload-manage'"
          >保存</el-button
        >
      </template>
      <div class="employeesItemEdit__editContainer">
        <div class="employeesItemEdit__editContainer__tabs">
          <el-tabs v-model="buttonActive">
            <el-tab-pane
              label="candidate info"
              name="edit-manage"
            ></el-tab-pane>
            <el-tab-pane
              label="attachements"
              name="upload-manage"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <component
          :jumpParams="jumpParams"
          ref="editManage"
          :is="buttonActive"
        />
      </div>
    </PocCardTable>
  </div>
</template>

<script>
import editManage from '../components/editManage.vue'
import uploadManage from '../components/uploadManage.vue'

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    editManage,
    uploadManage
  },
  created() {
    let jumpFlag = this.$route.query.jumpFlag
    let candidateInformationId = this.$route.query.candidateInformationId
      this.jumpParams = {
        jumpFlag,
        candidateInformationId
    }

  },
  data() {
    return {
      buttonActive: 'edit-manage',
      jumpParams: {}
    }
  },
  methods: {
    save() {
      this.$refs.editManage.save()
    },
    back() {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.employeesItemEdit {
  &__headerTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
</style>
